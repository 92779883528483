import Box from "components/Box";
import Button from "components/Button";
import moment from "moment";
import { hu } from "i18n/hu";

export default function ActiveProjectList(props) {
  const projects = props.active;

  return (
    <>
      <h5 className="mb-2">{hu.projects.active}</h5>
      {projects.map((item, index) => {
        return (
          <Box key={index} classes="active">
            <h2 className="h2 box__title">{item.name}</h2>
            <div className="box__projects">
              <div className="box__projects--details active">
                <em className="icon icon-stopper"></em>
                <span>
                  {moment(item.end_at).diff(item.start_at, "days")} {hu.labels.day}
                </span>
              </div>
              <div className="box__projects--details active">
                <em className="icon icon-check"></em>
                <span>{`${item.tasks_completed} / ${item.task_count}`}</span>
              </div>
              <div className="box__projects--details active">
                <em className="icon icon-point"></em>
                <span>{(item?.user_points || 0) + (item?.researcher_points || 0)}</span>
              </div>
            </div>
            <div className="flex">
              {item.status > 0 && (
                <Button type="link" to={`projektek/${item.id}`} classes="secondary">
                  <span>{item.tasks_completed > 0 ? hu.buttons.letsGo : hu.buttons.start}</span>
                </Button>
              )}
              <Button type="link" to={`projektek/${item.id}/reszletek`} classes="link">
                <span>{hu.buttons.details}</span>
              </Button>
            </div>
          </Box>
        );
      })}
    </>
  );
}
