import classNames from "classnames";
import FieldErrors from "components/error/FieldErrors";
import { hu } from "i18n/hu";

export default function Credentials({ register, watch, errors }) {
  return (
    <>
      <div className="form-field">
        <label htmlFor="email" className="form-label">
          {hu.user.labels.email}
        </label>
        <input
          id="email"
          type="email"
          className={classNames("form-input", { "has-error": errors.email })}
          placeholder={`${hu.user.placeholders.email}`}
          {...register("email", {
            minLength: 9,
            maxLength: 60,
            pattern: /^.+@.+$/
          })}
        />
        {errors?.email && <FieldErrors {...errors.email} />}
      </div>
      <div className="form-field">
        <label htmlFor="password" className="form-label">
          {hu.user.labels.password}
        </label>
        <input
          id="password"
          type="password"
          className={classNames("form-input", { "has-error": errors.password })}
          autoComplete="new-password"
          {...register("password", {
            minLength: 8
          })}
        />
        {errors?.password && <FieldErrors {...errors.password} />}
      </div>
      <div className="form-field">
        <label htmlFor="password_confirmation" className="form-label">
          {hu.user.labels.passwordConfirm}
        </label>
        <input
          id="password_confirmation"
          type="password"
          className={classNames("form-input", { "has-error": errors.password_confirmation })}
          {...register("password_confirmation", {
            minLength: 8,
            validate: value => {
              if (watch("password") !== value) {
                return false;
              }
            }
          })}
        />
        {errors?.password_confirmation && <FieldErrors {...errors.password_confirmation} />}
      </div>
    </>
  );
}
