import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useWebsocketState } from "hooks/websocketState";
import { useAuthState } from "hooks/authState";
import { useApiService } from "hooks/apiService";
import Chat from "components/chat/Chat";
import ChatMessage from "components/chat/ChatMessage";
import styles from "./Chat.module.scss";

export default function PageChat() {
  const { projectId } = useParams();
  const [{ pusher }] = useWebsocketState();
  const [authState] = useAuthState();
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const { get } = useApiService();

  const connectWs = () => {
    const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);

    wsProject.bind("ChatMessage:create", ({ data: entry }) => {
      setConversation(old => {
        return [...old, entry];
      });
    });
  };

  const fetchConversation = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data: response } = await get(`projects/${projectId}/chat`);
      setConversation(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      connectWs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    fetchConversation();

    return () => {
      pusher.unsubscribe("ChatMessage:create");
    };
  }, [fetchConversation, pusher]);

  return (
    <div className={styles.m}>
      <h1>Chat</h1>
      <Chat conversation={conversation} />
      <ChatMessage />
    </div>
  );
}
