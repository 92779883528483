import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useAuthState } from "hooks/authState";
import { useApiService } from "hooks/apiService";
import { useFetchUserData } from "hooks/fetchUserData";
import Slider from "components/slider/Slider";
import { hu } from "i18n/hu";
import styles from "./OnBoarding.module.scss";

export default function OnBoarding() {
  const [authState] = useAuthState();
  const { patch } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [serverError, setServerError] = useState(null);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { fetchUserData } = useFetchUserData();
  const navigate = useNavigate();
  const data = hu.onBoarding;

  useEffect(() => {
    setIsOnboarding(!authState?.user?.onboarding);
  }, [authState]);

  const submitOnBoarding = async e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await patch("/profile/onboarding");
      await fetchUserData();
      navigate("/profilom?setup");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  data[data.length - 1].itemCallback = { label: hu.buttons.letsGo, callback: submitOnBoarding };

  if (!isOnboarding) {
    return;
  }

  return (
    <div className={classNames(styles.m, "bg-white")}>
      <div className={styles.c}>
        <Slider items={data} />
      </div>
    </div>
  );
}
