import React from "react";

const { accessToken, tokenType } = JSON.parse(localStorage.getItem("credentials")) || {
  accessToken: null,
  tokenType: null
};

export const initialState = {
  isLoggedIn: (!!accessToken && !!tokenType) || false,
  user: JSON.parse(localStorage.getItem("user")) || null,
  credentials: JSON.parse(localStorage.getItem("credentials")) || null
};

export const stateAuthContext = React.createContext(initialState);
export const setAuthContext = React.createContext(undefined);

export function useAuthState() {
  return [React.useContext(stateAuthContext), React.useContext(setAuthContext)];
}

export function useSetAuth() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState);
}
