import { useState } from "react";
import { useLocalStorage } from "./localStorage";
import { useApiService } from "./apiService";
import { useAuthState } from "./authState";

export function useFetchUserData() {
  const { get } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const { store } = useLocalStorage();
  // eslint-disable-next-line no-unused-vars
  const [authState, setAuthState] = useAuthState();

  const fetchUserData = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await get("user", "profile");
      store("user", data.data);
      setAuthState({ user: data.data });
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchUserData };
}
