import { stateAuthContext, setAuthContext, useSetAuth } from "hooks/authState.js";

export default function AuthProvider({ children }) {
  const [authState, setAuthState] = useSetAuth();

  return (
    <stateAuthContext.Provider value={authState}>
      <setAuthContext.Provider value={setAuthState}>{children}</setAuthContext.Provider>
    </stateAuthContext.Provider>
  );
}
