import Box from "components/Box";
import Button from "components/Button";
import moment from "moment";
import { hu } from "i18n/hu";
import "moment/locale/hu";

export default function ActiveProjectList(props) {
  const projects = props.inactive;
  moment.locale("hu");

  return (
    <>
      <h5 className="mb-2">{hu.projects.inactive}</h5>
      {projects.map((item, index) => {
        return (
          <Box key={index} classes="inactive">
            <h2 className="h2 box__title">{item.name}</h2>
            <div className="box__projects--details inactive">
              <i className="icon icon-hourglass"></i>
              {moment(item.start_at).locale("hu").endOf("day").fromNow()}
            </div>

            <div className="flex -ml-5">
              <Button type="link" to={`projektek/${item.id}/reszletek`} classes="link">
                <span>{hu.buttons.details}</span>
              </Button>
            </div>
          </Box>
        );
      })}
    </>
  );
}
