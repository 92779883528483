import React from "react";

const Select = React.forwardRef(({ onChange, onBlur, id, name, label, additional_fields, required }, ref) => (
  <>
    <select
      name={`fields[field_${id}]`}
      ref={ref}
      onChange={onChange}
      onBlur={onBlur}
      required={required}
      className="form-input">
      {additional_fields &&
        additional_fields.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          );
        })}
    </select>
  </>
));

export default Select;
