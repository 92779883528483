const Input = props => {
  const { register, additional_fields, type, id, required } = props;

  return additional_fields && additional_fields.length > 0 ? (
    additional_fields.map((item, index) => {
      let step_middle;
      if (type === "range") {
        step_middle = item.max / 2;
      }

      return (
        <div key={index} className={`form-field w-full form-${type}__inner`}>
          <input
            type={type}
            id={`${type}_${index}${id}`}
            step={item.step}
            min={item.min}
            max={item.max}
            value={item.value}
            required={required}
            className={`form-${type}`}
            {...register(`fields[field_${id}]`)}
          />
          {item.label ? <label htmlFor={`${type}_${index}${id}`}>{item.label}</label> : ""}
          {step_middle && (
            <label htmlFor={`${type}_${id}"]`} className="form-label">
              <ul className="flex items-center justify-between my-2">
                <li>{item.min}</li>
                <li>{step_middle}</li>
                <li>{item.max}</li>
              </ul>
            </label>
          )}
        </div>
      );
    })
  ) : type === "textarea" ? (
    <textarea id={`${type}_${id}`} required={required} className="form-input" {...register(`fields[field_${id}]`)} />
  ) : (
    <input
      type={type}
      id={`${type}_${id}`}
      required={required}
      className="form-input"
      {...register(`fields[field_${id}]`)}
    />
  );
};

export default Input;
