import { stateModalContext, setModalContext, useSetModal } from "hooks/modalState";

export default function ModalProvider({ children }) {
  const [modalState, setModalState] = useSetModal();

  return (
    <stateModalContext.Provider value={modalState}>
      <setModalContext.Provider value={setModalState}>{children}</setModalContext.Provider>
    </stateModalContext.Provider>
  );
}
