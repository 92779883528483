import { useCallback } from "react";
import classNames from "classnames";
import { useAuthState } from "hooks/authState";
import styles from "./Chat.module.scss";

export default function Chat({ conversation }) {
  const [authState] = useAuthState();

  const handleRect = useCallback(node => {
    if (node) {
      node.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, []);

  return (
    <div className={styles.m}>
      {conversation.map((item, index) => (
        <div
          key={index}
          className={classNames(styles.entry, styles[authState.user.id === item.user?.id ? "my" : "mod"])}
          ref={handleRect}>
          <img src={item.user?.avatar} alt={item.user?.name} className={styles.avatar} />
          <strong className="mr-1">{item.user?.name}</strong>
          {item.message}
        </div>
      ))}
    </div>
  );
}
