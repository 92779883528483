import { Link } from "react-router-dom";

const Button = ({ type, to, children, classes }) => {
  return type !== "link" ? (
    <button className={`btn btn-${classes}`}>{children}</button>
  ) : (
    <Link to={`/${to}`} className={`btn btn-${classes}`}>
      {children}
    </Link>
  );
};

export default Button;
