import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { hu } from "i18n/hu";
import styles from "./TaskList.module.scss";

export default function TaskCompleted({ id, title, type, researcher_commments_count, researcher_points }) {
  let { projectId } = useParams();

  return (
    <>
      <em className={classNames(styles.i, ["icon", "icon-check"])}></em>
      <div className="flex">
        <Link to={`/projektek/${projectId}/feladatok/${id}/megoldas`} className="w-1/2">
          <p className={styles.title}>{title}</p>
          <p className={styles.type}>{hu.tasks.types[type].label}</p>
          <div className="flex justify-center gap-[5px]">
            {researcher_commments_count > 0 && (
              <div className="badge comment">
                {researcher_commments_count}
                <i className="icon icon-comment" />
              </div>
            )}
            {researcher_points > 0 && (
              <div className="badge points">
                {researcher_points}
                <i className="icon icon-point" />
              </div>
            )}
          </div>
        </Link>
        <div className="w-1/2">
          <span className="block">{hu.tasks.appreciateLabel}</span>
          <Link to={`/projektek/${projectId}/feladatok/${id}/feed`} className="btn btn-link">
            {hu.tasks.feed}
          </Link>
        </div>
      </div>
    </>
  );
}
