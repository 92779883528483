import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import Badge from "./Badge";
import { filesMap } from "configs/files-map";
import { hu } from "i18n/hu";

export default function CircuitList({ id, tasks, tasks_completed, task_count }) {
  let { projectId } = useParams();
  const [menuState, setMenuState] = useMenuState();

  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView({ block: "center", behavior: "smooth" });

  useEffect(() => {
    if (myRef.current) {
      executeScroll();
    }
  });

  if (!id) {
    return;
  }

  const rows = tasks && Math.ceil((tasks?.length + 2) / 2);

  const isFinished = tasks_completed >= task_count;

  return (
    <>
      <div className="tasks__link--inner completed" style={{ gridRow: rows }}>
        <button
          onClick={() =>
            setMenuState({ headerMenu: menuState.headerMenu === "task-onboarding" ? "" : "task-onboarding" })
          }
          className="box tasks__link">
          <span>{hu.tasks.start}</span>
        </button>
      </div>
      {tasks &&
        tasks.map((item, index) => {
          let classes;
          let isActive = false;
          let isCompleted = false;
          let isInactive = false;
          let icon = item.type;
          let style = { gridRow: rows - Math.floor((index + 1) / 2) };

          switch (true) {
            case tasks_completed === index:
              classes = "active";
              isActive = true;
              icon = "play";
              break;
            case tasks_completed > index:
              classes = "completed";
              isCompleted = true;
              icon = "check";
              break;
            default:
              classes = "inactive";
              isInactive = true;
          }

          return (
            <div
              className={classNames("tasks__link--inner", classes)}
              key={index}
              ref={isActive ? myRef : null}
              style={style}>
              {isActive && (
                <Link to={`/projektek/${projectId}/feladatok/${item.id}`} className={`tasks__link ${classes}`}>
                  <em className={`icon icon-${icon}`} />
                </Link>
              )}
              {isInactive && (
                <div className={`tasks__link ${classes}`}>
                  <em className={`icon icon-${filesMap[icon]}`} />
                </div>
              )}
              {isCompleted && (
                <Link to={`/projektek/${projectId}/feladatok/${item.id}/megoldas`} className={`tasks__link ${classes}`}>
                  <em className={`icon icon-${icon}`} />
                  <Badge {...item} />
                </Link>
              )}
            </div>
          );
        })}
      <div className={classNames("tasks__link--inner", isFinished ? "active" : "inactive")} style={{ gridRow: 1 }}>
        <Link
          to={`/projektek/${projectId}/shop`}
          className={classNames("box tasks__link", isFinished ? "active" : "inactive")}>
          <span>{hu.tasks.goal}</span>
        </Link>
      </div>
    </>
  );
}
