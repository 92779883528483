import React, { useState } from "react";
import { useSwipeable } from "react-swipeable";

import styles from "./Slider.module.scss";

export default function Slider({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = newIndex => {
    if (newIndex <= 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    setActiveIndex(newIndex);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return (
    <div className={styles.m}>
      <div className={styles.carousel} {...handlers}>
        <div className={styles.carousel__inner} style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
          {items.map((item, index) => (
            <div key={index} className={styles.carousel__item}>
              <img src={require(`assets/images/onboarding/${item.image}`)} alt={item.title} width={375} />
              <div className="text-center">
                <h2 className="text-h1 mb-[0.5em]">{item.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                {item?.itemCallback?.label ? (
                  <button className="btn btn-primary mt-[30px]" onClick={item?.itemCallback?.callback}>
                    {item?.itemCallback?.label}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.carousel__dots}>
        {items.map((child, index) => {
          return (
            <button
              key={index}
              className={`${styles.carousel__dots__item} ${index === activeIndex ? styles.active : ""}`}
              onClick={() => {
                updateIndex(index);
              }}>
              <span className="sr-only">{index + 1}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
}
