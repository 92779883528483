import React from "react";

export const initialState = false

export const stateModalContext = React.createContext(initialState);
export const setModalContext = React.createContext(undefined);

export function useModalState() {
  return [React.useContext(stateModalContext), React.useContext(setModalContext)];
}

export function useSetModal() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState);
}
