import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useApiService } from "hooks/apiService";
import TaskFeed from "components/feed/TaskFeed";
import { useWebsocketState } from "../hooks/websocketState";
import { useAuthState } from "../hooks/authState";
import {useModalState} from "../hooks/modalState";
import {useLocalStorage} from "../hooks/localStorage";

export default function PageTaskFeed() {
  const { projectId, taskId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { get } = useApiService();
  const [authState] = useAuthState();
  const [feed, setFeed] = useState({});
  const [modalState, setModalState] = useModalState();
  const { readStore, store } = useLocalStorage();
  const [{ pusher }] = useWebsocketState();

  const connectWs = () => {
    const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);
    const updateSolutionsFeed = (old, entry) => {
      const update = old
      if(update?.solutions) {
        update.solutions = old.solutions.map(item =>(item.id === entry.id ? entry : item));
      }
      return {...old, ...update}
    }
    wsProject.bind("Solution:create", ({ data: entry }) => {

      setFeed(old => {
       return  updateSolutionsFeed(old, entry);
      });

      store("projects", entry);
    });

    wsProject.bind("Solution:change", ({ data: entry }) => {
      setFeed(old => {
        return updateSolutionsFeed(old, entry);
      });
    });

    wsProject.bind("Project:change", ({ data: entry }) => {
      setModalState({openModal: !modalState})
      let updated = readStore("projects");
      if (updated.find(item => item.id === entry.id)) {
        updated = updated.map(item => {
          if(item.id === entry.id) {
            if (updated.user_remaining_points !== entry.user_remaining_points) {
              setModalState({openModal: !modalState})
            }
            return { ...item, ...entry }
          } else {
           return item
          }
        });
      } else {
        updated = [...updated, entry];
      }
      store("projects", updated);
    })
  };

  const fetchFeed = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data: response } = await get(`/projects/${projectId}/tasks/${taskId}/feed`);
      setFeed(response.data);
      setIsLoaded(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      connectWs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, taskId]);

  useEffect(() => {
    fetchFeed();

    return () => {
      pusher.unsubscribe("Solution:create");
      pusher.unsubscribe("Solution:change");
      pusher.unsubscribe("Project:change");
    };
  }, [fetchFeed, pusher]);

  return <>{isLoaded && <TaskFeed {...feed} /> } </>;
}
