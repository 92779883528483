
import { useParams } from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import { useApiService } from "hooks/apiService";
import SolutionFeed from "components/feed/SolutionFeed";
import {useWebsocketState} from "../hooks/websocketState";
import {useAuthState} from "../hooks/authState";

export default function PageSolutionFeed() {
  const { projectId, solutionId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { get } = useApiService();
  const [feed, setFeed] = useState();
  const [authState] = useAuthState();
  const [{ pusher }] = useWebsocketState();

  const connectWs = () => {
    const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);

    wsProject.bind("Solution:change", ({ data: entry }) => {
      setFeed({...entry})
    });
  }

  const fetchFeed = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data:response } = await get(`/projects/${projectId}/solutions/${solutionId}/comments`);
      setFeed(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      connectWs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);


  useEffect(() => {
    fetchFeed();

    return () => {
      pusher.unsubscribe("Solution:change");
    };
  }, [fetchFeed, pusher]);

  return <>{feed && <SolutionFeed {...feed} />}</>;
}
