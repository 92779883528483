import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import { useNotificationsState } from "hooks/notificationsState";
import styles from "./Menu.module.scss";
import { hu } from "i18n/hu";
import { useFetchNotifications } from "hooks/notifications";

export default function NotificationsMenu() {
  const location = useLocation();
  const [menuState, setMenuState] = useMenuState();
  const { fetchNotifications, setNotificationRead, releaseWs } = useFetchNotifications();
  const [notificationsState] = useNotificationsState();
  const navigate = useNavigate();

  useEffect(() => {
    setMenuState({ activeMenu: "" });
  }, [location, setMenuState]);

  useEffect(() => {
    const getNotifications = async () => {
      try {
        await fetchNotifications();
      } catch (error) {
        console.log("error", error);
      }
    };

    getNotifications();

    return () => {
      releaseWs();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const notificationClick = ({hash, title, type}) => {
    setNotificationRead(hash)
    if (type===0) {
      navigate("/dashboard");
    }
  }

  return (
    <div className={classNames(styles.m, { [styles.mA]: menuState.activeMenu === "notifications" })}>
      <em className={classNames("icon-close", styles.c)} onClick={() => setMenuState({ activeMenu: "" })}></em>

      <ul>
        <li className="font-quicksand text-[20px]">{hu.notifications.title}</li>
        {notificationsState.items && notificationsState.items.length ? (
          notificationsState.items.map((item, index) => {
            let icon = null;
            let bg;
            switch (true) {
              case item.type === 0:
                icon = `book`;
                bg = `bg-gray-200`;
                break;
              case item.type === 1:
                icon = `check`;
                bg = `bg-blue-base`;
                break;
              case item.type === 2:
                icon = `comment`;
                bg = `bg-green`;
                break;
              case item.type === 3:
                icon = `book`;
                bg = `bg-purple`;
                break;
              default:
            }

            return (
              <li
                key={index}
                onClick={() => notificationClick(item)}
                className={classNames("box", styles.notification)}>
                <span className={classNames(bg, styles.icon)}>
                  <em className={`icon icon-${icon}`}></em>
                </span>
                {item.message}
              </li>
            );
          })
        ) : (
          <li>{hu.notifications.empty}</li>
        )}
      </ul>
    </div>
  );
}
