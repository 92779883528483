import { stateWsContext, setWsContext, useSetWebsocket } from "hooks/websocketState.js";

export default function WebsocketProvider({ children }) {
  const [websocketState, setWebsocketState] = useSetWebsocket();

  return (
    <stateWsContext.Provider value={websocketState}>
      <setWsContext.Provider value={setWebsocketState}>{children}</setWsContext.Provider>
    </stateWsContext.Provider>
  );
}
