import { useMemo, useState } from "react";
import classNames from "classnames";
import FieldErrors from "components/error/FieldErrors";
import { hu } from "i18n/hu";

export default function Demographics({ register, errors }) {
  const [hasSetup, setHasSetup] = useState(false);

  useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    setHasSetup(params.has("setup"));
  }, [setHasSetup]);

  return (
    <>
      <div className="form-field">
        <label htmlFor="name" className="form-label">
          {hu.user.labels.name}
        </label>
        <input
          id="name"
          type="text"
          className={classNames("form-input", { "has-error": errors.name })}
          placeholder={`${hu.user.placeholders.name}`}
          {...register("name", {
            required: hasSetup,
            minLength: 8
          })}
        />
        {errors?.name && <FieldErrors {...errors.name} />}
      </div>
      <div className="form-field">
        <label htmlFor="username" className="form-label">
          {hu.user.labels.username}
        </label>
        <input
          id="username"
          type="text"
          className={classNames("form-input", { "has-error": errors.username })}
          placeholder={`${hu.user.placeholders.username}`}
          {...register("username", {
            required: hasSetup,
            minLength: 6
          })}
        />
        {errors?.username && <FieldErrors {...errors.username} />}
      </div>
      <div className="form-field">
        <label htmlFor="biography" className="form-label">
          {hu.user.labels.biography}
        </label>
        <textarea
          id="biography"
          className={classNames("form-input", { "has-error": errors.biography })}
          rows="4"
          {...register("biography")}
        />
        {errors?.biography && <FieldErrors {...errors.biography} />}
      </div>
      <div className="form-field">
        <label htmlFor="age" className="form-label">
          {hu.demographics.labels.age}
        </label>
        <input
          id="age"
          type="number"
          className={classNames("form-input", { "has-error": errors.age })}
          placeholder={`${hu.demographics.placeholders.age}`}
          {...register("age", {
            required: true
          })}
        />
        {errors?.age && <FieldErrors {...errors.age} />}
      </div>
      <div className="form-field">
        <label htmlFor="education_level" className="form-label">
          {hu.demographics.labels.educationLevel}
        </label>
        <select
          id="education_level"
          className={classNames("form-input", { "has-error": errors.education_level })}
          {...register("education_level", {
            required: true
          })}>
          <option disabled value="">
            {hu.demographics.placeholders.educationLevel}
          </option>
          {hu.demographics.educationLevels.map((item, index) => (
            <option key={index} value={index}>
              {item}
            </option>
          ))}
        </select>
        {errors?.education_level && <FieldErrors {...errors.education_level} />}
      </div>
      <div className="form-field">
        <label htmlFor="settlement_type" className="form-label">
          {hu.demographics.labels.settlementType}
        </label>
        <select
          id="settlement_type"
          className={classNames("form-input", { "has-error": errors.settlement_type })}
          {...register("settlement_type", {
            required: true
          })}>
          <option disabled value="">
            {hu.demographics.placeholders.settlementType}
          </option>
          {hu.demographics.settlementTypes.map((item, index) => (
            <option key={index} value={index}>
              {item}
            </option>
          ))}
        </select>
        {errors?.settlement_type && <FieldErrors {...errors.settlement_type} />}
      </div>
    </>
  );
}
