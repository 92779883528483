import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import { useAuthState } from "hooks/authState";
import { useFetchPages } from "hooks/fetchPages";
import Logout from "components/authentication/Logout";
import cameraImage from "assets/images/camera.svg";
import styles from "./Menu.module.scss";
import { hu } from "i18n/hu";

export default function AuthenticatedMenu() {
  const location = useLocation();
  const [menuState, setMenuState] = useMenuState();
  const [authState] = useAuthState();
  const [pageData, setPageData] = useState([]);
  const { fetchPageData } = useFetchPages();

  useEffect(() => {
    setMenuState({ activeMenu: "" });
  }, [location, setMenuState]);

  useEffect(() => {
    const getPages = async () => {
      try {
        const pageData = await fetchPageData();
        setPageData(pageData);
      } catch (error) {}
    };

    getPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(styles.m, { [styles.mA]: menuState.activeMenu === "mainMenu" })}>
      <em className={classNames("icon-close", styles.c)} onClick={() => setMenuState({ activeMenu: "" })}></em>
      <div className="box">
        <ul>
          <li>
            <Link to="/profilom" className={styles.user}>
              <span>
                <span className={styles.name}>{authState?.user?.name || hu.user.fallbackName}</span>
                <span className={styles.email}>{authState?.user?.email}</span>
              </span>
              <img
                src={authState?.user?.avatar || cameraImage}
                alt={authState?.user?.name || hu.user.fallbackName}
                className={styles.avatar}
              />
            </Link>
          </li>
          <li>
            <Link to="/profilom" className="btn rounded-full btn-secondary">
              {hu.mainMenu.profile}
            </Link>
          </li>
          <li>
            <Logout />
          </li>
        </ul>
      </div>
      {!!pageData.length && (
        <ul>
          <li className="font-quicksand text-[20px]">{hu.mainMenu.informations}</li>
          {pageData.map(item => (
            <li key={item.id}>
              <Link to={`/pages/${item.slug}`}>{item.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
