import {useEffect, useRef, useState} from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useApiService } from "hooks/apiService";
import UploadedFile from "components/UploadedFile";
import FieldErrors from "components/error/FieldErrors";
import ServerError from "components/error/ServerError";
import styles from "./Solution.module.scss";
import { hu } from "i18n/hu";
import { useParams } from "react-router-dom";

export default function Solution({ id, file, message, public: isPublic, isProcessing, task, messages }) {
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ block: "center", behavior: "smooth" });

  useEffect(() => {
    if (myRef.current) {
      executeScroll();
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    resetField
  } = useForm();

  const { save } = useApiService();

  const submitMessage = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await save(`/projects/${projectId}/solutions/${id}/message`, getValues());
      resetField("message");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <div className={styles.m}>
      <h2 className="text-h2 font-bold mb-4 text-center">{task.title}</h2>
      <div dangerouslySetInnerHTML={{ __html: task.description }} />
      <div className={classNames("box", styles.s)}>
        <div className={styles.tags}>
          <span className="bg-dark text-white">{hu.solutions.labels.tags[isPublic ? "public" : "private"]}</span>
          {isProcessing && <span className="bg-gray-200 text-white">{hu.solutions.labels.tags.processing}</span>}
          {messages?.length && (
            <span className={classNames(styles.b, styles.mB)}>
              {messages.length}
              <em className="icon-comment"></em>
            </span>
          )}
          {'researcher_points' in task &&
          (<span className={classNames(styles.b, styles.pB)}>
              {task?.researcher_points}
            <em className="icon-point"></em>
            </span>)
          }
        </div>
        <div>
          <strong className={styles.bt}>{hu.solutions.labels.uploaded[file.type]}</strong>
          <UploadedFile {...file} />
        </div>
        {message &&
          <div className="mt-5">
            <strong className={styles.bt}>{hu.labels.comment}</strong>
            {message}
          </div>
        }
      </div>
      {messages?.length > 0 && (
        <div className={styles.messages}>
          <h3 className="text-h3 mb-[10px] font-bold">{hu.solutions.labels.messages}</h3>
          {messages.map((item, index) => (
            <div className="box" key={index}>
              <img src={item.user.avatar} alt={item.user.name} className={styles.avatar} />
              <strong className="mr-1">{item.user.name}</strong>
              {item.message}
            </div>
          ))}
        </div>
      )}
      <form onSubmit={handleSubmit(submitMessage, onError)} className={styles.f}>
        <div className="flex gap-3">
          <div className="form-field flex-1">
            <label htmlFor="message" className="sr-only">
              {hu.solutions.labels.message}
            </label>
            <input
              id="message"
              type="text"
              ref={myRef}
              {...register("message", {
                required: true
              })}
              className={classNames("form-input", "h-[50px]", { "has-error": errors.message })}
              placeholder={hu.solutions.labels.messagePlaceholder}
            />
            {errors?.message && <FieldErrors {...errors.message} />}
          </div>
          <button
            type="submit"
            className="bg-blue-base text-white rounded-full w-[50px] h-[50px] flex items-center justify-center">
            <em className="icon-send"></em>
          </button>
        </div>
        {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
      </form>
    </div>
  );
}
