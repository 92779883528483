import { Outlet } from "react-router-dom";
import DefaultHeader from "./Header";
import styles from "./Layout.module.scss";

export default function DefaultLayout() {
  return (
    <div className={styles.m}>
      <header className={styles.h}>
        <DefaultHeader />
      </header>
      <div className={styles.c}>
        <Outlet />
      </div>
    </div>
  );
}
