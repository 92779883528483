import { useState } from "react";
import { useLocalStorage } from "./localStorage";
import { useApiService } from "./apiService";

export function useFetchProjects() {
  const { get } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const { store } = useLocalStorage();

  const fetchProjects = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await get("/projects");
      await store("projects", data.data);
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchProjects };
}
