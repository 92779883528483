import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import FieldErrors from "components/error/FieldErrors";
import ServerError from "components/error/ServerError";
import { useApiService } from "hooks/apiService";
import { hu } from "i18n/hu";

export default function Registration(props) {
  const { patch } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await patch("/registration", "", { ...getValues(), reg_hash: props.hash });
      navigate("/bejelentkezes");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="form-field">
          <label htmlFor="password" className="form-label">
            {hu.labels.password}
          </label>
          <input
            id="password"
            type="password"
            className={classNames("form-input", { "has-error": errors.password })}
            {...register("password", {
              required: true,
              minLength: 8
            })}
          />
          {errors?.password && <FieldErrors {...errors.password} />}
        </div>
        <div className="flex form-field">
          <label htmlFor="password_confirmation" className="form-label">
            {hu.labels.passwordConfirm}
          </label>
          <input
            id="password_confirmation"
            type="password"
            className={classNames("form-input", { "has-error": errors.password_confirmation })}
            {...register("password_confirmation", {
              required: true,
              minLength: 8
            })}
          />
          {errors?.password_confirmation && <FieldErrors {...errors.password_confirmation} />}
        </div>
        <div className="form-field">
          <input
            id="terms_condition"
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.terms_condition })}
            {...register("terms_condition", {
              required: true
            })}
          />
          <label htmlFor="terms_condition" className="form-label" dangerouslySetInnerHTML={{ __html: hu.labels.terms_and_condition }} />
          {errors?.terms_condition && <FieldErrors {...errors.terms_condition} />}
        </div>

        <div className="flex">
          <button type="submit" className="w-full btn btn-primary">
            {hu.buttons.register}
          </button>
        </div>
      </form>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
