import { useState } from "react";
import { useForm } from "react-hook-form";
import { hu } from "i18n/hu";
import { useApiService } from "hooks/apiService";
import ServerError from "components/error/ServerError";
import { filesMap, mimeMap } from "configs/files-map";
import styles from "./Upload.module.scss";

export default function Upload({ type, callback }) {
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [serverError, setServerError] = useState(null);
  const { save } = useApiService();

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField
  } = useForm({
    mode: "onChange"
  });

  const onChange = async ev => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", ev.fileToUpload[0]);
      const { data } = await save(`file-upload`, formData);
      resetField("fileToUpload");
      callback(data.data);
      setThumbnail(data.data.thumbnail_url);
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      <form onChange={handleSubmit(onChange, onError)}>
        <div className="form-field">
          <label htmlFor="fileToUpload" className={styles.e}>
            {thumbnail && <img src={thumbnail} alt="" className="mb-5" />}
            <div className="flex flex-col justify-center items-center">
              <div className="text-sm text-dark uppercase text-[10px] font-bold dark:text-gray-400 flex gap-[10px] items-center tracking-widest">
                <em className={`icon icon-${filesMap[type]} text-[40px]`}></em>
                {hu.tasks.types[type][thumbnail ? "replace" : "upload"]}
              </div>
            </div>
          </label>
          <div className="hidden">
            <input
              id="fileToUpload"
              type="file"
              {...register("fileToUpload", {
                required: true,
                validate: value => {
                  // console.log(value[0])
                  if (mimeMap[type].includes(value[0].type) && value[0].size <= 10485760) {
                    return value;
                  }
                  return false;
                }
              })}
            />
          </div>
          <p className="font-semibold">{hu.tasks.validVideo}</p>
        </div>
      </form>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
