import styles from "./TaskFeed.module.scss";
import Solution from "./Solution";

export default function TaskFeed({ id, title, description, solutions }) {
  return (
    <div className={styles.m}>
      <h2 className="text-h2 font-bold mb-4 text-center">{title}</h2>
      <div className="mb-5">{description}</div>
      {solutions.length && solutions.map(solution => <Solution key={solution.id} {...solution} />)}
    </div>
  );
}
