import styles from "./TaskList.module.scss";
import { filesMap } from "configs/files-map";
import classNames from "classnames";
import { hu } from "i18n/hu";

export default function TaskInactive({ title, type }) {
  return (
    <>
      <em className={classNames(styles.i, ["icon", `icon-${filesMap[type]}`])}></em>
      <p className={styles.title}>{title}</p>
      <p className={styles.type}>{hu.tasks.types[type].label}</p>
    </>
  );
}
