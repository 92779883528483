import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchProjectDetails } from "hooks/fetchProjectDetails";
import { useLocalStorage } from "hooks/localStorage";
import { useWebsocketState } from "hooks/websocketState";
import CircuitList from "components/steps/CircuitList";
import {useAuthState} from "../hooks/authState";

export default function PageCircuitView() {
  let { projectId } = useParams();
  const [projectDetails, setProjectDetails] = useState([]);
  const { fetchProjectDetails } = useFetchProjectDetails();
  const { readStore, store } = useLocalStorage();
  const [{ pusher }] = useWebsocketState();
  const [authState] = useAuthState();

  useEffect(() => {
    const connectWs = () => {
      const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);

      wsProject.bind("Project:change", ({ data: entry  }) => {
        store("projects", entry);
        setProjectDetails(entry);
      });
    };

    const getProject = async () => {
      try {
        await fetchProjectDetails(projectId);
        const store = readStore("projects");
        setProjectDetails(store);
      } catch (error) {
        console.log(error);
      } finally {
        connectWs();
      }
    };

    getProject();

    return () => {
      pusher.unsubscribe("Project:change");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tasks">
      <CircuitList {...projectDetails.find(item => item.id === parseInt(projectId))} />
    </div>
  );
}
