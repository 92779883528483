import React from "react";
import Pusher from "pusher-js";

const { accessToken } = JSON.parse(localStorage.getItem("credentials")) || {
  accessToken: null,
  tokenType: null
};

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER || null,
  wsHost: process.env.REACT_APP_PUSHER_HOST || "127.0.0.1",
  wsPort: parseInt(process.env.REACT_APP_PUSHER_PORT) || 443,
  httpHost: process.env.REACT_APP_PUSHER_HOST || "127.0.0.1",
  // httpPort: 8000,
  httpPath: "app",
  //authEndpoint: "broadcasting/auth",
  authEndpoint: process.env.REACT_APP_WEBSOCKET_AUTH_URL || "broadcasting/auth",
  auth: {
    headers: {
      //"X-CSRF-TOKEN": accessToken,
      Authorization: `Bearer ${accessToken}`
    }
  },
  forceTLS: process.env.REACT_APP_PUSHER_FORCE_TLS === "true",
  encrypted: process.env.REACT_APP_PUSHER_ENCRYPTED === "true",
  enabledTransports: ["ws"]
});

export const initialState = {
  pusher
};

export const stateWsContext = React.createContext(initialState);
export const setWsContext = React.createContext(undefined);

export function useWebsocketState() {
  return [React.useContext(stateWsContext), React.useContext(setWsContext)];
}

export function useSetWebsocket() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState);
}
