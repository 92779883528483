import { Link } from "react-router-dom";
import { hu } from "i18n/hu";
import { ReactComponent as Image } from "assets/images/404.svg";

export default function NotFound() {
  return (
    <div className="mt-[20px] text-center">
      <Image className="mb-[40px] mx-auto" />
      <div>
        <h1 className="text-h1 mb-[10px]">{hu.page404.title},</h1>
        <p className="mb-[20px]">{hu.page404.content}</p>
        <Link to="/" className="btn btn-primary">
          {hu.buttons.backToHome}
        </Link>
      </div>
    </div>
  );
}
