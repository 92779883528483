import { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMenuState } from "hooks/menuState";
import styles from "./Footer.module.scss";
import { hu } from "i18n/hu";
import { useNotificationsState } from "hooks/notificationsState";

export default function AuthenticatedFooter() {
  const location = useLocation();
  const { projectId } = useParams();
  const [isProjectActive, setIsProjectActive] = useState(false);
  const [isProjectDashboard, setIsProjectDashboard] = useState(false);
  const [notificationsState] = useNotificationsState();

  // eslint-disable-next-line no-unused-vars
  const [menuState, setMenuState] = useMenuState();

  useEffect(() => {
    setIsProjectActive(location.pathname.startsWith("/projektek"));
    setIsProjectDashboard(location.pathname.match(/projektek\/\d$/));
  }, [location]);

  return (
    <>
      {isProjectActive ? (
        <>
          {isProjectDashboard ? (
            <Link to={`/projektek/${projectId}/reszletek`} className={styles.i}>
              <em className="icon icon-list"></em>
              <span>{hu.footer.list}</span>
            </Link>
          ) : (
            <Link to={`/projektek/${projectId}`} className={styles.i}>
              <em className="icon icon-board"></em>
              <span>{hu.footer.board}</span>
            </Link>
          )}
          <Link to={`/projektek/${projectId}/chat`} className={styles.i}>
            <em className="icon icon-chat"></em>
            <span>{hu.footer.chat}</span>
          </Link>
          <Link to={`/projektek/${projectId}/shop`} className={styles.i}>
            <em className="icon icon-store"></em>
            <span>{hu.footer.store}</span>
          </Link>
        </>
      ) : (
        <Link to="/pages/segitseg" className={styles.i}>
          <em className="icon icon-question"></em>
          <span>{hu.footer.help}</span>
        </Link>
      )}
      <button onClick={() => setMenuState({ activeMenu: "notifications" })} className={styles.i}>
        <em className="icon icon-notification"></em>
        <span>{hu.footer.notification}</span>
        {notificationsState.count > 0 && <span className={styles.badge}> {notificationsState.count} </span>}
      </button>
      <button onClick={() => setMenuState({ activeMenu: "mainMenu" })} className={styles.i}>
        <em className="icon icon-hamburger"></em>
        <span>{hu.footer.menu}</span>
      </button>
    </>
  );
}
