import { hu } from "i18n/hu";

export default function ServerError({ error, handle }) {
  let errorCode = "unknown";

  if (error?.response?.status && [400, 401, 404, 422].includes(error.response.status)) {
    errorCode = error.response.status;
  }

  setTimeout(handle, 5000);

  if (error) {
    return <div>{hu.errors.serverErrors[errorCode] || hu.errors.serverErrors.unknown}</div>;
  }
}
