import React from "react";

export const initialState = {
  items: [],
  count: 0
};

export const stateNotificationsContext = React.createContext(initialState);
export const setNotificationsContext = React.createContext(undefined);

export function useNotificationsState() {
  return [React.useContext(stateNotificationsContext), React.useContext(setNotificationsContext)];
}

export function useSetNotifications() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState);
}
