import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiService } from "hooks/apiService";
import { hu } from "i18n/hu";
import ResetPassword from "components/authentication/ResetPassword";
import Password from "components/authentication/Password";

export default function PageResetPassword() {
  const navigate = useNavigate();
  const [token, setToken] = useState();
  const { get } = useApiService();

  useEffect(() => {
    let reset_token = null;

    let isLoading = false;

    const getToken = async () => {
      if (isLoading) {
        return;
      }

      isLoading = true;

      try {
        await get(`/password/${reset_token}`);
        setToken(reset_token);
        return Promise.resolve();
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading = false;
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("token")) {
      reset_token = urlParams.get("token");
      getToken();
    }
  }, [get, navigate]);

  return (
    <>
      <h1 className="text-h1 text-center">{hu.pages.resetPassword}</h1>
      {token ? <Password token={token} /> : <ResetPassword />}
    </>
  );
}
