import { useEffect, useState } from "react";
import { useFetchProjects } from "hooks/fetchProjects";
import { useLocalStorage } from "hooks/localStorage";
import { useWebsocketState } from "hooks/websocketState";
import { useAuthState } from "hooks/authState";
import ActiveProjectList from "components/projects/ActiveProjectList";
import InactiveProjectList from "components/projects/InactiveProjectList";

export default function PageDashboard() {
  const [projects, setProjects] = useState([]);
  const { fetchProjects } = useFetchProjects();
  const { readStore, store } = useLocalStorage();
  const [{ pusher }] = useWebsocketState();
  const [authState] = useAuthState();

  useEffect(() => {
    const connectWs = () => {
      if (authState?.user?.id) {
        const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);

        wsProject.bind("Project:change", ({ data }) => {
          let updated = readStore("projects");
          if (updated && updated.find(item => item.id === data.id)) {
            updated = updated.map(item => (item.id === data.id ? { ...item, ...data } : item));
          } else {
            updated = [...updated, data];
          }
          store("projects", updated);
          setProjects(updated);
        });
      }
    };

    const getProjects = async () => {
      await fetchProjects();
      setProjects(readStore("projects"));
      connectWs();
    };

    getProjects();

    return () => {
      pusher.unsubscribe("Project:change");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProjects, authState]);

  const filteredActiveProjects = projects ? projects.filter(item => item.status > 0) : [];
  const filteredInactiveProjects = projects ? projects.filter(item => item.status === 0) : [];

  return (
    <>
      <h1 className="sr-only">Dashboard</h1>
      <ActiveProjectList active={filteredActiveProjects} />
      <InactiveProjectList inactive={filteredInactiveProjects} />
    </>
  );
}
