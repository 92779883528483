import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import FieldErrors from "components/error/FieldErrors";
import ServerError from "components/error/ServerError";
import { useApiService } from "hooks/apiService";
import { hu } from "i18n/hu";

export default function ResetPassword() {
  const { save } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await save("/forgot-password", getValues());
      navigate("/elfelejtett-jelszo/koszonjuk");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="form-field">
          <label htmlFor="email" className="form-label">
            {hu.labels.email}
          </label>
          <input
            id="email"
            type="email"
            placeholder="mintajanos@gmail.com"
            className={classNames("form-input", { "has-error": errors.email })}
            autoFocus
            {...register("email", {
              required: true,
              minLength: 9,
              maxLength: 60,
              pattern: /^.+@.+$/
            })}
          />
          {errors?.email && <FieldErrors {...errors.email} />}
        </div>

        <div className="form-field">
          <button type="submit" className="w-full btn btn-primary">
            {hu.buttons.sendForgotPassword}
          </button>
        </div>
      </form>
      <div className="form-field">
        <Link to="/bejelentkezes" type="submit" className="w-full btn btn-link">
          {hu.buttons.backLogin}
        </Link>
      </div>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
