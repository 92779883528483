import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiService } from "hooks/apiService";
import { hu } from "i18n/hu";
import Registration from "components/authentication/Register";

export default function PageRegister() {
  const navigate = useNavigate();
  const [hash, setHash] = useState(null);
  const { get } = useApiService();

  useEffect(() => {
    let isLoading = false;
    let reg_hash = null;

    const getHash = async () => {
      if (isLoading) {
        return;
      }

      isLoading = true;

      try {
        await get(`/registration/${reg_hash}`);
        setHash(reg_hash);
        return Promise.resolve();
      } catch (error) {
        console.log("error", error);
      } finally {
        isLoading = false;
      }
    };

    const params = new URLSearchParams(window.location.search);

    if (params.has("reg_hash")) {
      reg_hash = params.get("reg_hash");
      getHash();
    } else {
      navigate("/bejelentkezes");
    }
  }, [navigate, get]);

  return (
    <>
      <h1 className="text-h1 text-center">{hu.pages.registration}</h1>
      {hash ? <Registration hash={hash} /> : <p className="text-center">{hu.errors.registration.notFound}</p>}
    </>
  );
}
