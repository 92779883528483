import { useEffect, useState } from "react";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import { useLocalStorage } from "hooks/localStorage";
import styles from "./PointsMenu.module.scss";
import { hu } from "i18n/hu";
import { useParams } from "react-router-dom";

export default function ProjectsMenu() {
  let { projectId } = useParams();
  const [menuState, setMenuState] = useMenuState();
  const [projectDetails, setProjectDetails] = useState();
  const { readStore } = useLocalStorage();

  useEffect(() => {
    const projects = readStore("projects")
    if (projects && projects.length) {
      setProjectDetails(projects.find(item => item.id === parseInt(projectId)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!projectDetails) {
    return;
  }

  return (
    <div
      className={classNames(styles.m, { [styles.mA]: menuState.headerMenu === "points" })}
      onClick={() => setMenuState({ headerMenu: "" })}>
      <h3 className="text-h2 mb-2 font-bold">{hu.pointsMenu.title}</h3>
      <p>{hu.pointsMenu.description}</p>
      {projectDetails && <div className={styles.points}>
        <div>
          <span className={styles.point}>{projectDetails?.researcher_points || 0}</span>
          <span className={styles.label}>{hu.pointsMenu.fromModerator}</span>
        </div>
        <div>
          <span className={styles.point}>{projectDetails?.user_points || 0}</span>
          <span className={styles.label}>{hu.pointsMenu.fromOther}</span>
        </div>
      </div>}
    </div>
  );
}
