import Reward from "./Reward";
import { hu } from "i18n/hu";

export default function Rewards({ rewards }) {
  return (
    <>
      <p className="text-center max-w-[250px] mx-auto">{hu.rewards.description}</p>
      {rewards.map(item => (
        <Reward key={item.id} {...item}  />
      ))}
    </>
  );
}
