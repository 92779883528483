import { useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useApiService } from "hooks/apiService";
import FieldErrors from "components/error/FieldErrors";
import ServerError from "components/error/ServerError";
import styles from "./ChatMessage.module.scss";

export default function ChatMessage() {
  const { projectId } = useParams();
  const { save } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    resetField
  } = useForm();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await save(`/projects/${projectId}/chat`, getValues());
      resetField("message");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full flex gap-3 mt-auto">
        <div className={classNames("form-field", "flex-1", styles.field)}>
          <label htmlFor="message" className="sr-only">
            Üzenet
          </label>
          <input
            id="message"
            type="text"
            {...register("message", { required: true })}
            className={classNames("form-input", "h-[50px]", { "has-error": errors.message })}
          />
          {errors?.message && <FieldErrors {...errors.message} />}
        </div>
        <button
          type="submit"
          className="bg-blue-base text-white rounded-full w-[50px] h-[50px] flex items-center justify-center">
          <em className="icon-send"></em>
        </button>
      </form>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
