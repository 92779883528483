export const filesMap = {
  image: "photo",
  selfie: "photo",
  video: "video",
  selfie_video: "video",
  image_documentation: "photo",
  video_documentation: "video"
};

export const mimeMap = {
  image: ["image/jpeg", "image/png", "image/gif"],
  selfie: ["image/jpeg", "image/png", "image/gif"],
  video: ["video/mp4", "video/mpeg", "video/ogg", "video/x-msvideo", "video/x-matroska"],
  selfie_video: ["video/mp4", "video/mpeg", "video/ogg", "video/x-msvideo", "video/x-matroska"],
  image_documentation: ["image/jpeg", "image/png", "image/gif"],
  video_documentation: ["video/mp4", "video/mpeg", "video/ogg", "video/x-msvideo", "video/x-matroska"]
};
