import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { useApiService } from "hooks/apiService";
import { useLocalStorage } from "hooks/localStorage";
import { hu } from "i18n/hu";
import styles from "./Reward.module.scss";

export default function Reward({ id, name, description, image, points }) {
  let { projectId } = useParams();
  const { save } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const { readStore } = useLocalStorage();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isConfirmation, setIsConfirmation] = useState(false);

  useEffect(() => {
    // maybe create a project provider later
    const projects = readStore("projects");
    if (projects && projects.length) {
      const store = projects.find(item => item.id === parseInt(projectId));
      const availablePoints = (store?.researcher_points || 0) + (store?.user_points || 0);
      setIsDisabled(availablePoints < points || store.status !== 2);
    } else {
      setIsDisabled(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async () => {
    if (isLoading || isDisabled) {
      return;
    }

    setIsLoading(true);

    try {
      await save(`/projects/${projectId}/rewards/${id}`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsConfirmation(false);
    }
  };

  return (
    <>
      <div className={classNames("box", styles.m)}>
        <img src={image} alt={name}/>
        <h2>{name}</h2>
        <div>{description}</div>
        <div className={styles.points}>
          {points} <em className="icon icon-point text-purple"></em>
        </div>
        <button onClick={() => setIsConfirmation(true)} className="btn btn-primary" disabled={isDisabled}>
          {hu.rewards.buy}
        </button>
      </div>
      <div className={classNames(styles.modal, {[styles.isConfirmation]: isConfirmation})}>
        <div className="box">
          <p className="mb-2">{hu.rewards.confirm.title}</p>
          <p><strong className="text-xl">{name}</strong></p>
          <div className="flex justify-center gap-2">
            <button className="btn" onClick={() => setIsConfirmation(false)}>{hu.rewards.confirm.no}</button>
            <button className="btn btn-primary" onClick={submit}>{hu.rewards.confirm.yes}</button>
          </div>
        </div>
      </div>
    </>
  );
}
