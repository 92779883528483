import { useMemo, useState } from "react";
import Profile from "components/user/Profile";
import SetupProfile from "components/user/SetupProfile";

export default function PageProfile() {
  const [hasSetup, setHasSetup] = useState(false);

  useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    setHasSetup(params.has("setup"));
  }, [setHasSetup]);

  return <>{hasSetup ? <SetupProfile /> : <Profile />}</>;
}
