import { stateMenuContext, setMenuContext, useSetMenu } from "hooks/menuState.js";

export default function MenuProvider({ children }) {
  const [menuState, setMenuState] = useSetMenu();

  return (
    <stateMenuContext.Provider value={menuState}>
      <setMenuContext.Provider value={setMenuState}>{children}</setMenuContext.Provider>
    </stateMenuContext.Provider>
  );
}
