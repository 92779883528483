import { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { useAuthState } from "hooks/authState";
import { useOneSignal } from "hooks/oneSignalService";

import DefaultLayout from "components/layout/Layout";
import AuthenticatedLayout from "components/layout/AuthenticatedLayout";

import PageHome from "pages/Home";
import Page404 from "pages/404";
import PageDashboard from "pages/Dashboard";
import PageUnauthenticated from "pages/Unauthenticated";
import PageLogin from "pages/Login";
import PageProjects from "pages/Projects";
import PageCircuitView from "pages/CircuitView";
import PageTasksView from "pages/TasksView";
import PageTask from "pages/Task";
import PageResetPasswordThankYou from "pages/ResetThankYou";
import PageResetPassword from "pages/Resetpassword";
import PageRegister from "pages/Register";
import PageProfile from "pages/Profile";
import PageShop from "pages/Shop";
import PageChat from "pages/Chat";
import PageSolution from "pages/Solution";
import PageTaskFeed from "pages/TaskFeed";
import PageSolutionFeed from "pages/SolutionFeed";
import Page from "pages/Page";

export default function Providers() {
  const [authState] = useAuthState();
  const { oneSignalHandler } = useOneSignal();

  useEffect(() => {
    oneSignalHandler(authState.isLoggedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLoggedIn]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthenticatedLayout />}>
          <Route
            path="/dashboard"
            element={authState.isLoggedIn ? <PageDashboard /> : <Navigate replace to="/bejelentkezes" />}
          />
          <Route path="/projektek">
            <Route index element={authState.isLoggedIn ? <PageProjects /> : <PageUnauthenticated />} />
            <Route
              path="/projektek/:projectId"
              element={authState.isLoggedIn ? <PageCircuitView /> : <PageUnauthenticated />}
            />
            <Route
              path="/projektek/:projectId/reszletek"
              element={authState.isLoggedIn ? <PageTasksView /> : <PageUnauthenticated />}
            />
            <Route
              path="/projektek/:projectId/feladatok/:taskId"
              element={authState.isLoggedIn ? <PageTask /> : <PageUnauthenticated />}
            />
            <Route
              path="/projektek/:projectId/feladatok/:taskId/feed"
              element={authState.isLoggedIn ? <PageTaskFeed /> : <PageUnauthenticated />}
            />
            <Route
              path="/projektek/:projectId/feladatok/:taskId/megoldas"
              element={authState.isLoggedIn ? <PageSolution /> : <PageUnauthenticated />}
            />
            <Route
              path="/projektek/:projectId/feladatok/:taskId/megoldasok/:solutionId/feed"
              element={authState.isLoggedIn ? <PageSolutionFeed /> : <PageUnauthenticated />}
            />
          </Route>
          <Route
            path="/profilom"
            element={authState.isLoggedIn ? <PageProfile /> : <Navigate replace to="/bejelentkezes" />}
          />
          <Route
            path="/projektek/:projectId/shop"
            element={authState.isLoggedIn ? <PageShop /> : <Navigate replace to="/bejelentkezes" />}
          />
          <Route
            path="/projektek/:projectId/chat"
            element={authState.isLoggedIn ? <PageChat /> : <Navigate replace to="/bejelentkezes" />}
          />
        </Route>
        <Route element={<DefaultLayout />}>
          <Route path="/" element={authState.isLoggedIn ? <Navigate replace to="/dashboard" /> : <PageHome />} />
          <Route
            path="/bejelentkezes"
            element={authState.isLoggedIn ? <Navigate replace to="/dashboard" /> : <PageLogin />}
          />
          <Route
            path="/elfelejtett-jelszo"
            element={authState.isLoggedIn ? <Navigate replace to="/dashboard" /> : <PageResetPassword />}
          />
          <Route
            path="/elfelejtett-jelszo/koszonjuk"
            element={authState.isLoggedIn ? <Navigate replace to="/dashboard" /> : <PageResetPasswordThankYou />}
          />
          <Route
            path="/regisztracio"
            element={authState.isLoggedIn ? <Navigate replace to="/dashboard" /> : <PageRegister />}
          />
          <Route path="/pages/:pageSlug" element={<Page />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
