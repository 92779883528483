import { useState } from "react";
import { useAuthState } from "hooks/authState";
import { useApiService } from "hooks/apiService.js";
import { useLocalStorage } from "hooks/localStorage.js";
import { hu } from "i18n/hu";

export default function Logout() {
  const { store } = useLocalStorage();
  const { save } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [authState, setAuthState] = useAuthState();

  const logout = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await save("logout");

      store("credentials", null);
      store("user", null);
      store("projects", null);
      setAuthState({ isLoggedIn: false });
      return Promise.resolve();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <span onClick={logout} className="cursor-pointer p-2 -mx-2">
      {hu.mainMenu.logout}
    </span>
  );
}
