import { useEffect, useState } from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import classNames from "classnames";
import Logo from "assets/images/logo.svg";
import { useMenuState } from "hooks/menuState";
import { useLocalStorage } from "hooks/localStorage";
import styles from "./Header.module.scss";
import { hu } from "i18n/hu";

export default function AuthenticatedHeader() {
  const location = useLocation();
  let { projectId } = useParams();
  const [isProjectActive, setIsProjectActive] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [menuState, setMenuState] = useMenuState();
  const { readStore } = useLocalStorage();

  useEffect(() => {
    const projects = readStore("projects");
    if (projects && projects.length) {
      setIsProjectActive(location.pathname.startsWith("/projektek"));
      const store = projects.find(item => item.id === parseInt(projectId));
      setProjectDetails(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {isProjectActive && (
        <div className={styles.i}>
          <em className="icon icon-accepted"></em>
          {`${projectDetails?.tasks_completed} / ${projectDetails?.task_count} `}
        </div>
      )}
      <Link to="/dashboard" className="mx-auto">
        <img src={Logo} className="inline-block h-[24px]" alt={hu.appName} />
      </Link>
      {isProjectActive && projectDetails && (
        <div
          className={classNames(styles.i, "cursor-pointer")}
          onClick={() => setMenuState({ headerMenu: menuState.headerMenu === "points" ? "" : "points" })}>
          {(projectDetails?.researcher_points || 0) + (projectDetails?.user_points || 0)}
          <em className="icon icon-point text-purple"></em>
        </div>
      )}
    </>
  );
}
