import { stateNotificationsContext, setNotificationsContext, useSetNotifications } from "hooks/notificationsState.js";

export default function NotificationsProvider({ children }) {
  const [notificationsState, setNotificationsState] = useSetNotifications();

  return (
    <stateNotificationsContext.Provider value={notificationsState}>
      <setNotificationsContext.Provider value={setNotificationsState}>{children}</setNotificationsContext.Provider>
    </stateNotificationsContext.Provider>
  );
}
