import Login from "components/authentication/Login";
import { hu } from "i18n/hu";

export default function PageLogin() {
  return (
    <>
      <h1 className="text-h1 text-center">{hu.pages.login}</h1>
      <Login />
    </>
  );
}
