import { Link, useParams } from "react-router-dom";
import { hu } from "i18n/hu.js";
import styles from "./TaskList.module.scss";

export default function TaskActive({ id, title, type }) {
  let { projectId } = useParams();

  return (
    <>
      <p className={styles.title}>{title}</p>
      <p className={styles.type}>{hu.tasks.types[type].label}</p>
      <Link to={`/projektek/${projectId}/feladatok/${id}`} className="btn btn-secondary">
        {hu.tasks.types[type].upload}
      </Link>
    </>
  );
}
