import React from "react";

export const initialState = {
  activeMenu: "",
  headerMenu: "",
  openModal: ""
};

export const stateMenuContext = React.createContext(initialState);
export const setMenuContext = React.createContext(undefined);

export function useMenuState() {
  return [React.useContext(stateMenuContext), React.useContext(setMenuContext)];
}

export function useSetMenu() {
  return React.useReducer((state, newValue) => ({ ...state, ...newValue }), initialState);
}
