import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { diff } from "deep-object-diff";
import { useApiService } from "hooks/apiService";
import { useFetchUserData } from "hooks/fetchUserData";
import ServerError from "components/error/ServerError";
import Loader from "components/Loader";
import Demographics from "./Demographics";
import ProfileAvatar from "./ProfileAvatar";
import Credentials from "./Credentials";
import NotificationSettings from "./NotificationSettings";
import { hu } from "i18n/hu";

export default function Profile() {
  const { patch, get } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [userData, setUserData] = useState({});
  const navigate = useNavigate();
  const { fetchUserData } = useFetchUserData();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    watch,
    setValue,
    getValues,
    reset
  } = useForm({
    defaultValues: {
      name: "",
      username: "",
      age: "",
      education_level: "",
      settlement_type: "",
      avatar: "",
      email: "",
      password: "",
      password_confirmation: "",
      points_notification: "",
      points_email: "",
      messages_notification: "",
      messages_email: "",
      projects_notification: "",
      projects_email: ""
    }
  });

  useEffect(() => {
    const fetchProfileDetails = async () => {
      if (isFetching) {
        return;
      }

      setIsFetching(true);

      try {
        const { data } = await get("user", "profile", {
          includes: "details"
        });

        setUserData(data.data);
        delete data.data.id;
        delete data.data.onboarding;
        reset(data.data);

        setIsFetched(true);
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        return Promise.reject();
      } finally {
        setIsFetching(false);
      }
    };

    fetchProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    if (isLoading || !isValid) {
      return;
    }

    setIsLoading(true);

    try {
      const payload = diff(userData, getValues());
      await patch("/user/profile", "", payload);

      await fetchUserData();
      navigate("/dashboard");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      {!isFetching && !isFetched && hu.user.fetchError}
      {isFetching && <Loader />}
      {isFetched && (
        <>
          <form onSubmit={handleSubmit(onSubmit, onError)} className="flex flex-col h-full" autoComplete="off">
            <ProfileAvatar avatar={userData.avatar} callback={data => setValue("avatar", data?.file_id)} />
            <Demographics register={register} errors={errors} />
            <Credentials register={register} watch={watch} errors={errors} />
            <NotificationSettings register={register} errors={errors} />
            <div className="flex gap-5 mt-auto">
              <button type="submit" className="btn btn-primary flex-1" disabled={isSubmitting}>
                {hu.buttons.forward}
              </button>
            </div>
          </form>
          {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
        </>
      )}
    </>
  );
}
