import { useState } from "react";
import { useLocalStorage } from "./localStorage";
import { useApiService } from "./apiService";
import { useNotificationsState } from "./notificationsState";
import { useWebsocketState } from "./websocketState";
import {useAuthState} from "./authState";

export function useFetchNotifications() {
  const { get, remove } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const { store, readStore } = useLocalStorage();
  const [notificationsState, setNotificationsState] = useNotificationsState();
  const [{ pusher }] = useWebsocketState();
  const [authState] = useAuthState();

  const connectWs = () => {
    if (authState?.user?.id) {
      const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);
      wsProject.bind("Notification:change", ({ data }) => {

        let updated = readStore("notifications") || [];
        if (updated.find(item => item.hash === data.hash)) {
          updated = updated.map(item => (item.hash === data.hash ? { ...item, ...data } : item));
        } else {
          updated = [...updated, data];
        }
        store("notifications", updated);
        setNotificationsState({
          items: updated,
          count: updated.length
        });
      });
    }
  };

  const releaseWs = () => {
    pusher.unsubscribe("Notification:change");
  };

  const fetchNotifications = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { raw, data } = await get("/notifications");
      if (raw.status !== 204) {
        store("notifications", data.data);
        setNotificationsState({ items: data.data, count: data.data.length });
      } else {
        store("notifications", []);
        setNotificationsState([{ items: [], count: 0 }]);
      }

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setIsLoading(false);
      connectWs();
    }
  };

  const setNotificationRead = async hash => {
    if (!notificationsState.count || !notificationsState.items.find(item => item.hash === hash)) {
      return;
    }

    if (isLoading) {
      return;
    }

    try {
      await remove("/notifications", hash);
      let notitifications = readStore("notifications");
      notitifications = notitifications.filter(item => item.hash !== hash);
      store("notifications", notitifications);
      setNotificationsState({ items: notitifications, count: notitifications.length });
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchNotifications, setNotificationRead, releaseWs };
}
