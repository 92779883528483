import Router from "./Router";
import AuthProvider from "./Auth";
import MenuProvider from "./Menu";
import NotificationsProvider from "./Notifications";
import WebsocketProvider from "./Websocket";
import ModalProvider from "./Modal";

export default function Providers() {
  return (
    <AuthProvider>
      <WebsocketProvider>
        <MenuProvider>
          <ModalProvider>
            <NotificationsProvider>
              <Router />
            </NotificationsProvider>
          </ModalProvider>
        </MenuProvider>
      </WebsocketProvider>
    </AuthProvider>
  );
}
