import { Link } from "react-router-dom";
import { hu } from "i18n/hu";

export default function PageHome() {
  return (
    <div className="text-center">
      <h1 className="text-h1 mb-5">HOME</h1>
      <Link to="/bejelentkezes" className="btn btn-primary">
        {hu.buttons.login}
      </Link>
    </div>
  );
}
