import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import { hu } from "i18n/hu";

export default function DefaultHeader() {
  return (
    <Link to="/">
      <img src={Logo} className="inline-block h-[24px]" alt={hu.appName} />
    </Link>
  );
}
