import styles from "./PageData.module.scss";

export default function PageData({ title, content }) {
  return (
    <>
      <h1 className="mb-4">{title}</h1>
      {content && <div className={styles.c} dangerouslySetInnerHTML={{ __html: content }} />}
    </>
  );
}
