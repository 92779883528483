const Badge = ({ researcher_commments_count, researcher_points, user_points }) => {
  return (
    <>
      {researcher_commments_count > 0 && (
        <div className="badge comment">
          {researcher_commments_count}
          <em className="icon icon-comment" />
        </div>
      )}
      {(researcher_points > 0 || user_points > 0) && (
        <div className="badge points">
          {(researcher_points || 0) + (user_points || 0)}
          <em className="icon icon-point" />
        </div>
      )}
    </>
  );
};

export default Badge;
