import { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import Box from "components/Box";
import TaskActive from "./TaskActive";
import TaskInactive from "./TaskInactive";
import TaskCompleted from "./TaskCompleted";
import styles from "./TaskList.module.scss";
import { hu } from "i18n/hu";

export default function TaskList({ id, tasks, tasks_completed, task_count }) {
  let { projectId } = useParams();
  const [menuState, setMenuState] = useMenuState();
  const myRef = useRef(null);

  const executeScroll = () => myRef.current.scrollIntoView({ block: "center", behavior: "smooth" });

  useEffect(() => {
    if (myRef.current) {
      executeScroll();
    }
  });

  if (!id) {
    return;
  }

  const isFinished = tasks_completed >= task_count;

  return (
    <>
      <div className={classNames(styles.t, styles.completed)}>
        <Box classes="completed">
          <em className={classNames(styles.i, ["icon", "icon-play"])}></em>
          <button
            onClick={() =>
              setMenuState({ headerMenu: menuState.headerMenu === "task-onboarding" ? "" : "task-onboarding" })
            }>
            <span className="uppercase font-bold">{hu.tasks.start}</span>
          </button>
        </Box>
      </div>
      {tasks &&
        tasks.map((item, index) => {
          let classes;
          let isActive = false;
          let isCompleted = false;
          let isInactive = false;

          switch (true) {
            case tasks_completed === index:
              classes = "active";
              isActive = true;
              break;
            case tasks_completed > index:
              classes = "completed";
              isCompleted = true;
              break;
            default:
              classes = "inactive";
              isInactive = true;
          }

          return (
            <div className={classNames(styles.t, styles[classes])} key={index} ref={isActive ? myRef : null}>
              <Box classes={classes}>
                {isActive && <TaskActive {...item} />}
                {isInactive && <TaskInactive {...item} />}
                {isCompleted && <TaskCompleted {...item} />}
              </Box>
            </div>
          );
        })}
      <div className={classNames(styles.t, isFinished ? styles.active : styles.inactive, "mt-[25px]")}>
        <Box classes={isFinished ? "active" : "inactive"}>
          <em className={classNames(styles.i, ["icon", "icon-hourglass"])}></em>
          <p className="pt-2">
            <Link to={`/projektek/${projectId}/shop`}>
              <span className="uppercase font-bold">{hu.tasks.goal}</span>
            </Link>
          </p>
        </Box>
      </div>
    </>
  );
}
