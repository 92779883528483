import { useState } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useApiService } from "hooks/apiService";
import UploadedFile from "components/UploadedFile";
import FieldErrors from "components/error/FieldErrors";
import ServerError from "components/error/ServerError";
import styles from "./SolutionFeed.module.scss";
import { hu } from "i18n/hu";
import { useParams } from "react-router-dom";

export default function SolutionFeed({ id, message, file, user, user_points, isLiked, task, comments }) {
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    resetField
  } = useForm();

  const { save } = useApiService();

  const submitComment = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      await save(`/projects/${projectId}/solutions/${id}/comments`, getValues());
      resetField("comment");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  const submitLike = async () => {
    // TODO: isLiked ws needed, or handle in place
    if (isLoading || isLiked) {
      return;
    }

    setIsLoading(true);

    try {
      await save(`/projects/${projectId}/solutions/${id}/like`);
      return Promise.resolve();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.m}>
      <h2 className="text-h2 font-bold mb-4 text-center">{task.title}</h2>
      <div className="mb-5" dangerouslySetInnerHTML={{ __html: task.description }} />
      <div className="box">
        <UploadedFile {...file} />
        <div className="flex justify-between items-center mt-4">
          <div className={styles.b} onClick={() => submitLike()}>
            <em className="icon-point" />
            {user_points}
          </div>
          <div className={styles.b}>
            {comments?.length}
            <em className="icon-comment" />
          </div>
        </div>
        <div className={styles.t}>
          <img src={user.avatar} alt={user.name} className={styles.avatar} />
          <strong className="mr-1">{user.name}</strong>
          {message}
        </div>
        {comments?.length && (
          <div className={styles.l}>
            <strong>{hu.feed.labels.comments}</strong>
            {comments.map(item => (
              <div key={item.id} className={styles.c}>
                <img src={item.user.avatar} alt={item.user.name} className={styles.avatar} />
                <div>
                  <strong className="mr-1">{item.user.name}</strong>
                  {item.comment}
                </div>
              </div>
            ))}
          </div>
        )}
        <form onSubmit={handleSubmit(submitComment, onError)} className={styles.f}>
          <div className="flex gap-3">
            <div className="form-field flex-1">
              <label htmlFor="comment" className="sr-only">
                {hu.solutions.labels.message}
              </label>
              <input
                id="comment"
                type="text"
                {...register("comment", {
                  required: true
                })}
                className={classNames("form-input", "h-[50px]", { "has-error": errors.comment })}
                placeholder={hu.solutions.labels.messagePlaceholder}
              />
              {errors?.comment && <FieldErrors {...errors.comment} />}
            </div>

            <button
              type="submit"
              className="bg-blue-base text-white rounded-full w-[50px] h-[50px] flex items-center justify-center">
              <em className="icon-send"></em>
            </button>
          </div>
          {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
        </form>
      </div>
    </div>
  );
}
