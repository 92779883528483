import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiService } from "hooks/apiService";
import Task from "components/steps/Task";

const PageTask = () => {
  const { projectId, taskId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { get } = useApiService();
  const [step, setStep] = useState();

  useEffect(() => {
    const fetchStep = async () => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      try {
        const { data } = await get(`/projects/${projectId}/tasks/${taskId}`);
        setStep(data.data);
        return Promise.resolve();
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return step && <Task {...step} />;
};

export default PageTask;
