import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchPages } from "hooks/fetchPages";
import Loader from "components/Loader";
import NotFound from "components/pages/NotFound";
import PageData from "components/pages/PageData";

export default function Page() {
  const { pageSlug } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageData, setPageData] = useState({});
  const { fetchPageData } = useFetchPages();

  useEffect(() => {
    const getPage = async () => {
      if (isLoading) {
        return;
      }

      setError(false);
      setIsLoading(true);

      try {
        const pageData = await fetchPageData(pageSlug);
        setPageData(pageData);
      } catch (error) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSlug]);

  return (
    <>
      {pageData && <PageData {...pageData} />}
      {isLoading && <Loader />}
      {error && <NotFound />}
    </>
  );
}
