import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApiService } from "hooks/apiService";
import Rewards from "components/shop/Rewards";
import { hu } from "i18n/hu";

export default function PageShop() {
  let { projectId } = useParams();
  const { get } = useApiService();
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getRewards = async () => {
      if (isLoading) {
        return;
      }

      setIsLoading(true);
      try {
        const { data: response } = await get(`/projects/${projectId}/rewards`);
        setRewards(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getRewards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h1 className="sr-only">{hu.rewards.title}</h1>
      {isLoading && "loading"}
      {!isLoading && rewards.length > 0 && <Rewards rewards={rewards} />}
    </>
  );
}
