import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import { useLocalStorage } from "hooks/localStorage";
import { useApiService } from "hooks/apiService";
import ServerError from "components/error/ServerError";
import { hu } from "i18n/hu";
import ReactPlayer from "react-player";
import { useParams } from "react-router-dom";
import styles from "./TaskOnboardingMenu.module.scss";

export default function ProjectsMenu() {
  let { projectId } = useParams();
  const [menuState, setMenuState] = useMenuState();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { readStore } = useLocalStorage();
  const { patch } = useApiService();
  const [projectDetails, setProjectDetails] = useState(false)

  useEffect(() => {
    const projects = readStore("projects");
    if (projects && projects.length) {
      const store = projects.find(item => item.id === parseInt(projectId));
      setProjectDetails(store);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const videoConfig = {
    youtube: {
      playerVars: {
        showinfo: 0
      },
      embedOptions: {
        rel: 0
      }
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await patch(`projects/${projectId}/onboarding_video`);
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={classNames("box", styles.m, { [styles.mA]: menuState.headerMenu === "task-onboarding" })}
      onClick={() => setMenuState({ headerMenu: "" })}>
      {projectDetails && (
        <>
          <h3 className="w-full text-h2 text-center mb-2 font-bold">{hu.tasks.letsgo}</h3>
          <p>{projectDetails.brief}</p>
          <ReactPlayer url={projectDetails.start_video} width="100%" height="250px" controls={true} config={videoConfig} />
          <button className="btn btn-secondary mt-[30px]" onClick={handleSubmit}>
            kezdjük el
          </button>
        </>
      )}
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </div>
  );
}
