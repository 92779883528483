import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import {useModalState} from "hooks/modalState";
import {useLocalStorage} from "hooks/localStorage";
import {hu} from "i18n/hu";
import styles from "./Modal.module.scss";

export default function Modal() {
  const { projectId } = useParams();
  const [ modalState, setModalState ] = useModalState();
  const {readStore} = useLocalStorage();
  const [projectDetails, setProjectDetails] = useState();


  useEffect(() => {
    const projects = readStore("projects");
    if (projects && projects.length) {
      setProjectDetails(projects.find(item => item.id === parseInt(projectId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!projectDetails) {
    return;
  }

  return (
      <div className={classNames('box' )}>
        <div className="flex justify-end">
          <em className={classNames("icon icon-close cursor-pointer", styles.c)} onClick={() => setModalState({openModal: !modalState})}></em>
        </div>
        <div className={styles.points}>
          <div className="mb-3">
            <span className={styles.point}>{projectDetails?.user_remaining_points || 0}</span>
          </div>
        </div>
        <h2 className="mb-3 text-bold">{hu.modal.userPoint}</h2>
        <p>{hu.modal.content.replace(':max_points', projectDetails?.user_max_points).replace(':remaining_points', projectDetails?.user_remaining_points)}
        </p>
      </div>
  )
}
