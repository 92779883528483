import { useApiService } from "./apiService";
import OneSignal from "react-onesignal";

export function useOneSignal() {
  const { save } = useApiService();

  const oneSignalHandler = async isLoggedIn => {
    if (!process.env.REACT_APP_ONESIGNAL_APP_ID || !isLoggedIn) {
      return;
    }

    try {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID
      });

      OneSignal.on("subscriptionChange", async function (isSubscribed) {
        if (isSubscribed) {
          try {
            const userId = await OneSignal.getUserId();

            await save("user/subscribe-onesignal", { user_id: userId });
          } catch (error) {
            console.log(error);
          }
        }
      });

      await OneSignal.showSlidedownPrompt();
    } catch (error) {
      console.log(error);
    }
  };

  return { oneSignalHandler };
}
