import { useApiService } from "./apiService";

export function useFetchPages() {
  const { get } = useApiService();
  const resource = "pages";

  const fetchPageData = async (pageSlug = "") => {
    try {
      const { data } = await get(resource, pageSlug);
      return Promise.resolve(data.data);
    } catch (error) {
      console.log("error", error);
      return Promise.reject();
    }
  };

  return { fetchPageData };
}
