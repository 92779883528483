import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";

import FieldErrors from "components/error/FieldErrors";
import ServerError from "components/error/ServerError";
import { useApiService } from "hooks/apiService";
import { hu } from "i18n/hu";

export default function Password(props) {
  const { patch } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues
  } = useForm();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await patch("/reset-password", "", getValues());
      navigate("/bejelentkezes");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="form-field">
          <label htmlFor="password" className="form-label">
            {hu.labels.password}
          </label>
          <input
            id="password"
            type="password"
            className={classNames("form-input", { "has-error": errors.password })}
            {...register("password", {
              required: true,
              minLength: 8,
            })}
          />
          {errors?.password && <FieldErrors {...errors.password} />}
        </div>
        <div className="form-field">
          <label htmlFor="password_confirmation" className="form-label">
            {hu.labels.passwordConfirm}
          </label>
          <input
            id="password_confirmation"
            type="password"
            className={classNames("form-input", { "has-error": errors.password_confirmation })}
            {...register("password_confirmation", {
              required: true,
              minLength: 8
            })}
          />
          {errors?.password_confirmation && <FieldErrors {...errors.password_confirmation} />}
        </div>

        <input id="token" type="hidden" {...register("token", { value: props.token })} />
        <div className="form-field">
          <button type="submit" className="w-full btn btn-primary">
            {hu.buttons.forward}
          </button>
        </div>
      </form>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
