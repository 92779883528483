export const hu = {
  appName: "ERA",
  page404: {
    title: "Sajnáljuk",
    content: "de a keresett tartalom nem elérhető."
  },
  labels: {
    email: "e-mail",
    password: "jelszó",
    passwordConfirm: "ismételt jelszó",
    terms_and_condition: "<a href='/pages/adatkezelesi-tajekoztato' target='_blank' class='underline'>adatvédelmi tájékoztató</a> elfogadása",
    comment: "megjegyzés",
    intoForum: "Közzéteszem a fórumban",
    fileUpload: "feltöltés URL alapján",
    day: "nap"
  },
  thankyou: {
    wait: "türelem",
    content: "Jelszavad visszaállításához szükséges linket elküldtük neked a megadott e-mail címre."
  },
  buttons: {
    register: "regisztrálok",
    login: "belépek",
    forgotPassword: "elfelejtett jelszó",
    sendForgotPassword: "jelszókérelem elküldése",
    backLogin: "vissza a belépéshez",
    back: "vissza",
    forward: "tovább",
    letsGo: "Folytatom",
    start: "Elkezdem",
    details: "Részletek",
    backToHome: "vissza a főoldalra",
    save: "mentés"
  },
  errors: {
    validations: {
      required: "Ez a mező kötelező.",
      maxLength: "A mező túl hosszú.",
      minLength: "A mező túl rövid",
      pattern: "A mező formátuma nem megfelelő",
      validate: "A mező értéke nem megfelelő"
    },
    serverErrors: {
      unknown: "Ismeretlen hiba történt",
      400: "Hibás kérés történt",
      401: "A művelet végrehajtásához be kell jelentkezni",
      404: "Nem található",
      422: "A művelet nem hajtható végre"
    },
    registration: {
      notFound: "Ez a meghívó már nem használható."
    }
  },
  pages: {
    registration: "Regisztráció",
    login: "Belépés",
    resetPassword: "Elfelejtett jelszó",
    onBoarding: ""
  },
  projects: {
    active: "Aktív kihívások",
    inactive: "Hamarosan induló kihívások"
  },
  tasks: {
    appreciateLabel: "Értékeld te is a többiek munkáját",
    types: {
      image: {
        label: "Illusztráció",
        upload: "Kép feltöltése",
        replace: "Kép cseréje"
      },
      video: {
        label: "Videó",
        upload: "Videó feltöltése",
        replace: "Videó cseréje"
      },
      selfie: {
        label: "Selfie",
        upload: "Selfie feltöltése",
        replace: "Selfie cseréje"
      },
      selfie_video: {
        label: "Selfie videó",
        upload: "Selfie videó feltöltése",
        replace: "Selfie videó cseréje"
      },
      image_documentation: {
        label: "Fotódokumentáció",
        upload: "Kép feltöltése",
        replace: "Kép cseréje"
      },
      video_documentation: {
        label: "Videódokumentáció",
        upload: "Videó feltöltése",
        replace: "Videó cseréje"
      }
    },
    validvideo: "Támogatott formátumok: AVI, MP4, MKV. A feltöltendő fájl mérete nem haladhatja meg a 10 MB-ot.",
    validphoto: "Támogatott formátumok: JPG, JPEG, PNG, GIF. A feltöltendő fájl mérete nem haladhatja meg a 10 MB-ot.",
    letsgo: "kezdjük el",
    start: "start",
    goal: "cél",
    feed: "fórum"
  },
  solutions: {
    labels: {
      uploaded: {
        video: "Feltöltött videók",
        image: "Feltöltött képek"
      },
      tags: {
        private: "Privát tartalom",
        public: "Publikus tartalom",
        processing: "Értékelés alatt"
      },
      messages: "Értékelés és hozzászólások",
      message: "Üzenet",
      messagePlaceholder: "Hozzászólás szövege..."
    }
  },
  feed: {
    labels: {
      comments: "Hozzászólások"
    }
  },
  onBoarding: [
    {
      image: "step1.png",
      title: "Kihívások",
      content:
        "<p>Most nem egy szokványos kutatásban veszel részt! Ez olyan, mint egy társasjáték, ígyhát egy pályán kell végigmenned, hogy teljesítsd a kihívást.</p><p><strong>Mire kell számítanod?</strong><br/>Izgalmas feladatokra és közösségi élményekre</p><p><strong>Mire lesz szükséged?</strong><br/>A telefonodra és a kreativitásodra</p>"
    },
    {
      image: "step2.png",
      title: "Pontgyűjtés",
      content:
        "<p>Minden kihívás pontokat ér, ami a pénztárcádban landol, de csak rajtad múlik mennyit gyűjtesz.</p><p>A feltöltött tartalmaidat a moderátor értékeli elsőként, de ha megosztod őket a Többiekkel is, akkor tőlük is értékes pontokat zsebelhetsz be.</p>"
    },
    {
      image: "step3.png",
      title: "Pontadás",
      content: "<p>Nem csak gyűjthetsz, de adhatsz is pontokat a Többieknek...</p>"
    },
    {
      image: "step4.png",
      title: "Pontbeváltás",
      content:
        "<p>A kutatás végén az összegyűjtött pontokat egy virtuális ajándékboltban tudod levásárolni. Itt érdemes aktívnak lenni :)</p>"
    }
  ],
  footer: {
    board: "Pálya",
    chat: "Chat",
    help: "Segítség",
    list: "Feladat",
    menu: "Menü",
    notification: "Értesítés",
    store: "Shop"
  },
  mainMenu: {
    profile: "Profil megtekintése",
    logout: "Kijelentkezés",
    informations: "Információk"
  },
  notifications: {
    title: "Értesítések",
    empty: "Jelenleg nincs olvasatlan értesítés"
  },
  user: {
    fallbackName: "felhasznalo",
    labels: {
      name: "Teljes neved",
      username: "Beceneved",
      biography: "Bemutatkozás",
      email: "E-mail",
      password: "Password",
      passwordConfirm: "Jelszó újra"
    },
    placeholders: {
      name: "Fókusz Fanni",
      username: "Fanni",
      email: "fokuszfanni@gmail.com"
    },
    notifications: {
      headline: "Értesítések",
      points: "Új pont",
      messages: "Értékelés",
      projects: "Új projekt"
    },
    setup: {
      step1: {
        title: "Profil adatok",
        description: "Töltsd ki a profilodat és kezdd el a pontgyűjtést!"
      },
      step2: {
        title: "Profilkép",
        description:
          "Már csak egy profilkép hiányzik az induláshoz!<br/>Ettől lesz teljes a profilod, ezért olyan képet válassz, ami jellemző rád."
      }
    },
    fetchError: "Hiba történt az adatok lekérése során. Kérlek, próbáld meg később."
  },
  demographics: {
    labels: {
      age: "Életkorod",
      educationLevel: "Iskolai végzettséged",
      settlementType: "Lakóhelyed"
    },
    placeholders: {
      age: "28",
      educationLevel: "Kérlek, válassz a listából...",
      settlementType: "Kérlek, válassz a listából..."
    },
    educationLevels: ["Főiskola, vagy egyetem"],
    settlementTypes: ["Megyeszékhely"]
  },
  pointsMenu: {
    title: "Kapott pontjaim",
    description:
      "Az elvégzett feladatokért kapott pontjaidat itt követheted nyomon, melyeket a moderátoroktól és a résztvevőktől egyaránt begyűjthetsz.\nAz összegyűjtött pontokért értékes ajándékokat vásárolhatsz virtuális bevásárlóközpontunkban.",
    fromModerator: "Moderátortól kapott",
    fromOther: "Másoktól kapott"
  },
  rewards: {
    title: "Jutalmak",
    description: "A projekt lezárultával itt válthatod be az összegyűjtött pontjaidat.",
    buy: "Begyűjtöm",
    confirm: {
      title: "Biztos ezt választod?",
      yes: "Igen",
      no: "Nem"
    }
  },
  unauthenticated: {
    title: "Az oldal megtekintéséhez jelentkezz be"
  },
  modal: {
    userPoint: "Adható pontjaid",
    content: `A rendelkezésedre álló :max_points pontból, amit a projekt során odaadhatsz a Többieknek, már csak :remaining_points maradt.`,
  }
};
