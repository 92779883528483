import Input from "components/inputs/Input";
import Select from "components/inputs/Select";

const Fields = props => {
  return (
    <>
      {props &&
        props?.fields?.map((item, index) => {
          let type = item.type;
          let select = false;
          switch (true) {
            case type === "select":
              select = true;
              break;
            default:
          }

          return (
            <div className="form-field" key={index}>
              <label className="form-label">{item.label}</label>
              {!select && <Input {...item} register={props.register} />}
              {select && <Select {...props.register(`fields[field_${item.id}]`)} {...item} />}
            </div>
          );
        })}
    </>
  );
};

export default Fields;
