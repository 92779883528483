import { useState } from "react";
import { useForm } from "react-hook-form";
import { useApiService } from "hooks/apiService";
import { mimeMap } from "configs/files-map";
import ServerError from "components/error/ServerError";
import styles from "./ProfileAvatar.module.scss";
import classNames from "classnames";

export default function ProfileAvatar({ avatar, callback }) {
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState(null);
  const [serverError, setServerError] = useState(null);
  const { save } = useApiService();

  const { register, resetField } = useForm({
    mode: "onChange"
  });

  const onChange = async event => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      const { data } = await save(`file-upload`, formData);
      resetField("fileToUpload");
      callback(data.data);
      setThumbnail(data.data.thumbnail_url);
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="form-field">
        <label htmlFor="fileToUpload" className={classNames(styles.e, { [styles.t]: thumbnail || avatar })}>
          {avatar || thumbnail ? (
            <>
              <img src={avatar || thumbnail} alt="" />
              <em className="icon icon-edit text-[20px] w-[40px] h-[40px] rounded-full flex items-center justify-center bg-blue-base text-white absolute top-0.5 right-2" />
            </>
          ) : (
            <>
              <em className="icon icon-camera text-[75px] text-[#d9d9d9]" />
            </>
          )}
        </label>
        <div className="hidden">
          <input
            id="fileToUpload"
            type="file"
            {...register("fileToUpload", {
              required: true,
              validate: value => {
                if (mimeMap.image.includes(value[0].type) && value[0].size <= 10485760) {
                  return value;
                }
                return false;
              },
              onChange
            })}
          />
        </div>
      </div>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
