import { Outlet } from "react-router-dom";
import classNames from "classnames";
import { useMenuState } from "hooks/menuState";
import {useModalState} from "hooks/modalState";
import AuthenticatedHeader from "./AuthenticatedHeader.js";
import AuthenticatedFooter from "./AuthenticatedFooter.js";
import AuthenticatedMenu from "./AuthenticatedMenu";
import NotificationsMenu from "./NotificationsMenu";
import PointsMenu from "./PointsMenu";
import TaskOnboardingMenu from "./TaskOnboardingMenu";
import OnBoarding from "./OnBoarding.js";
import Modal from "./Modal.js";
import styles from "./Layout.module.scss";

export default function AuthenticatedLayout() {
  const [menuState] = useMenuState();
  const [modalState] = useModalState();

  return (
    <div className={styles.m}>
      <header className={styles.h}>
        <AuthenticatedHeader />
      </header>
      <div className={styles.c}>
        <Outlet />
      </div>
      <footer className={styles.f}>
        <AuthenticatedFooter />
      </footer>
      <nav className={classNames(styles.n, { [styles.nOpen]: menuState.activeMenu })}>
        <AuthenticatedMenu />
        <NotificationsMenu />
      </nav>
      <nav className={classNames(styles.tn, { [styles.nOpen]: menuState.headerMenu })}>
        <PointsMenu />
      </nav>
      <nav className={classNames(styles.ov, { [styles.nOpen]: menuState.headerMenu })}>
        <TaskOnboardingMenu />
      </nav>
      <nav className={classNames(styles.modal, { [styles.nOpen]: modalState.openModal })}>
        <Modal/>
      </nav>
      <OnBoarding />
    </div>
  );
}
