import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { hu } from "i18n/hu";
import { useApiService } from "hooks/apiService";
import ServerError from "components/error/ServerError";
// import UploadFileUrl from "./UploadFileUrl";
import Upload from "./Upload";
// import Thumbnails from "./Thumbnails";
import Fields from "./Fields";

const Task = ({ description, title, type, fields }) => {
  let { projectId, taskId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  // const [thumbnails, setThumbnails] = useState();
  const { save } = useApiService();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue
  } = useForm();

  const onSubmit = async () => {
    if (isLoading) {
      return;
    }

    console.log(getValues())

    setIsLoading(true);
    try {
      await save(`projects/${projectId}/tasks/${taskId}/solution`, getValues());
      navigate(`/projektek/${projectId}`);
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log(errors);
  };

  return (
    <>
      <h2 className="mb-5 text-h2 text-center">{title}</h2>
      {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      {<Upload type={type} callback={data => setValue("file_id", data.file_id)} />}

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <input type="hidden" {...register("file_id", { required: true })} />
        <div className="form-field">
          <label htmlFor="comment" className="form-label">
            {hu.labels.comment}
          </label>
          <textarea
            id="comment"
            className="form-input"
            {...register(`comment_${taskId}`, {
              required: false
            })}
          />
        </div>
        {<Fields fields={fields} register={register} />}
        <div className="form-field">
          <label htmlFor="public" className="inline-flex relative items-center cursor-pointer">
            <input type="checkbox" {...register("public")} id="public" className="sr-only peer" />
            <div className="form-toggle"></div>
            <span className="mx-2 dark:text-gray-100">{hu.labels.intoForum}</span>
            <em className="icon icon-info" />
          </label>
        </div>

        <div className="flex">
          <button type="submit" className="w-full btn btn-primary">
            {hu.buttons.save}
          </button>
        </div>
      </form>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
};

export default Task;
