import classNames from "classnames";
import FieldErrors from "components/error/FieldErrors";
import { hu } from "i18n/hu";
import styles from "./NotificationSettings.module.scss";

export default function NotificationSettings({ register, errors }) {
  return (
    <div className={classNames(styles.m, "box")}>
      <div className={styles.r}>
        <span className="text-[20px]">{hu.user.notifications.headline}</span>
        <em className="icon icon-mobile"></em>
        <em className="icon icon-email"></em>
      </div>
      <div className={styles.r}>
        <span>{hu.user.notifications.points}</span>
        <div className="field-group">
          <input
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.points_notification })}
            {...register("points_notification")}
          />
          {errors?.points_notification && <FieldErrors {...errors.points_notification} />}
        </div>
        <div className="field-group">
          <input
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.points_email })}
            {...register("points_email")}
          />
          {errors?.points_email && <FieldErrors {...errors.points_email} />}
        </div>
      </div>
      <div className={styles.r}>
        <span>{hu.user.notifications.messages}</span>
        <div className="field-group">
          <input
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.messages_notification })}
            {...register("messages_notification")}
          />
          {errors?.messages_notification && <FieldErrors {...errors.messages_notification} />}
        </div>
        <div className="field-group">
          <input
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.messages_email })}
            {...register("messages_email")}
          />
          {errors?.messages_email && <FieldErrors {...errors.messages_email} />}
        </div>
      </div>
      <div className={styles.r}>
        <span>{hu.user.notifications.projects}</span>
        <div className="field-group">
          <input
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.projects_notification })}
            {...register("projects_notification")}
          />
          {errors?.projects_notification && <FieldErrors {...errors.projects_notification} />}
        </div>
        <div className="field-group">
          <input
            type="checkbox"
            className={classNames("form-checkbox", { "has-error": errors.projects_email })}
            {...register("projects_email")}
          />
          {errors?.projects_email && <FieldErrors {...errors.projects_email} />}
        </div>
      </div>
    </div>
  );
}
