import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useApiService } from "hooks/apiService";
import { useFetchUserData } from "hooks/fetchUserData";
import ServerError from "components/error/ServerError";
import { hu } from "i18n/hu";
import Demographics from "./Demographics";
import ProfileAvatar from "./ProfileAvatar";

export default function SetupProfile() {
  const { patch } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { fetchUserData } = useFetchUserData();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      name: "",
      username: "",
      age: "",
      education_level: "",
      settlement_type: "",
      avatar: ""
    }
  });

  const submit = async () => {
    if (isLoading || !isValid) {
      return;
    }

    setIsLoading(true);

    try {
      await patch("/user/profile", "", getValues());

      await fetchUserData();
      navigate("/dashboard");
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    step === 0 ? setStep(1) : submit();
  };

  const onError = () => {
    console.log(errors);
  };

  const stepBack = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="flex flex-col h-full">
        {step === 0 ? (
          <>
            <h1 className="text-center mb-2">{hu.user.setup.step1.title}</h1>
            <p className="text-center">{hu.user.setup.step1.description}</p>
            <Demographics register={register} errors={errors} />
          </>
        ) : (
          <>
            <h1 className="text-center mb-3">{hu.user.setup.step2.title}</h1>
            <p className="text-center" dangerouslySetInnerHTML={{ __html: hu.user.setup.step2.description }} />
            <ProfileAvatar callback={data => setValue("avatar", data?.file_id)} />
          </>
        )}
        <div className="flex gap-5 mt-auto">
          <button type="submit" className="btn btn-secondary flex-1" disabled={step === 0} onClick={stepBack}>
            {hu.buttons.back}
          </button>
          <button type="submit" className="btn btn-primary flex-1" disabled={isSubmitting}>
            {hu.buttons.forward}
          </button>
        </div>
      </form>
      {serverError && <ServerError error={serverError} handle={() => setServerError(null)} />}
    </>
  );
}
