import { useState } from "react";
import { useLocalStorage } from "./localStorage";
import { useApiService } from "./apiService";

export function useFetchProjectDetails() {
  const { get } = useApiService();
  const [isLoading, setIsLoading] = useState(false);
  const { store, readStore } = useLocalStorage();
  const [project] = useState(readStore('projects'));

  const fetchProjectDetails = async id => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await get(`/projects/${id}`);
      store('projects', project.map(item =>
      {
        if (item.id === data.data.id){
          return {...item, ...data.data}; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item
      }));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchProjectDetails };
}
