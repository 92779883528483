import { hu } from "../i18n/hu";
import { Link } from "react-router-dom";

export default function PageResetPasswordThankYou() {
  return (
    <>
      <i className="icon"></i>
      <h1 className="text-h1">{hu.thankyou.wait}</h1>
      <p>{hu.thankyou.content}</p>
      <div className="flex">
        <Link to="/bejelentkezes" type="submit" className="btn btn-primary">
          {hu.buttons.backLogin}
        </Link>
      </div>
    </>
  );
}
