import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useApiService } from "hooks/apiService";
import { useWebsocketState } from "hooks/websocketState";
import { useAuthState } from "hooks/authState";
import Solution from "components/solutions/Solution";

export default function PageSolution() {
  const { projectId, taskId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { get } = useApiService();
  const [solution, setSolution] = useState();
  const [{ pusher }] = useWebsocketState()
  const [authState] = useAuthState();

  const connectWs = () => {
    const wsProject = pusher.subscribe(`private-User.${authState.user.id}`);

    wsProject.bind("Solution:change", ({ data: entry }) => {
      setSolution({ ...entry });
    });
  };

  const fetchSolution = useCallback(async () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const { data: response } = await get(`/projects/${projectId}/tasks/${taskId}/solution`);
      setSolution(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      connectWs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, taskId]);

  useEffect(() => {
    fetchSolution();

    return () => {
      pusher.unsubscribe("Solution:change");
    };
  }, [fetchSolution, pusher]);

  return <>{solution && <Solution {...solution} />}</>;
}
