import ReactPlayer from "react-player";

// TODO: video doesn't handled
export default function UploadedFile({ id, url, thumbnail_url, type }) {
  const videoConfig = {
    youtube: {
      playerVars: {
        showinfo: 0
      },
      embedOptions: {
        rel: 0
      }
    }
  };

  return (
    <>
      {type === "image" && <img src={thumbnail_url} className="rounded-[10px]" alt="Feltöltött kép" />}
      {type === "video" && <ReactPlayer url={url} width="100%" height="250px" controls={true} config={videoConfig} />}
    </>
  );
}
